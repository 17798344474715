<script>
    export let id = `folder-selector-${randomHex()}`;
    export let operation = async () => {};
    export let onClose = () => {};
    export let doNotAllow = [];
    export let executeType = '';

    import { randomHex } from '../../providers/utils';
    import NewFolderComponent from '../new.folder.svelte';
    import ListLoader from '../list.loader.svelte';
    import EmptyFolder from './empty.folder.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import FolderIcon from '../../icons/folder.svelte';
    import AddIcon from '../../icons/add.svelte';
    import ModalComponent from '../elements/modal.svelte';
    import { i18n } from '../../i18n';
    import { gDriveInfo } from '../../providers/state/user';
    import { FolderFactory } from '../../rest.sdks';

    const dataCy = 'folder-selector';
    const dataCyFsExecute =
        'fs-execute' + (executeType ? `-${executeType}` : '');

    let modalInstance;
    let folder;
    let getContent = [];
    let history = [];
    let selectedFolder = {};

    const assignGetContent = () => {
        getContent = folder.content.sortedByName(true).then((content) => {
            return content.folders.filter((folderItem) => {
                return !doNotAllow.find((item) => item.id === folderItem.id);
            });
        });
    };

    $: {
        if (!folder) {
            const { rootFolderId } = $gDriveInfo;
            folder = FolderFactory(rootFolderId);
        }

        assignGetContent();
        folder.observe({
            content: () => {
                assignGetContent();
            },
        });
        selectedFolder = folder;
    }

    const gotoFolderHandler = (folderItem) => {
        return () => {
            if (folder) {
                // https://svelte.dev/tutorial/updating-arrays-and-objects
                history = [...history, folder];
            }
            folder = folderItem;
            selectedFolder = {};
        };
    };

    const selectFolder = (folderItem) => {
        return () => {
            // Tweak for singleclick vs dblclick
            if (selectedFolder && folderItem.id === selectedFolder.id) {
                gotoFolderHandler(folderItem)();
            } else {
                selectedFolder = folderItem;
            }
        };
    };

    const goBack = () => {
        folder = history.pop();
        // https://svelte.dev/tutorial/updating-arrays-and-objects
        history = [...history];
    };

    const options = {
        onCloseEnd: onClose,
    };

    const execute = async () => {
        operation(selectedFolder);

        // The modal could have been 'Close'd while we operate, so gaurd aganst that
        if (modalInstance) {
            modalInstance.close();
        }
    };
</script>

{#if folder}
    <ModalComponent
        {id}
        {dataCy}
        bind:this={modalInstance}
        {options}
        condensedHeader={true}
    >
        <div
            class="columns is-marginless has-all-space is-vertical-center is-mobile"
            slot="title"
        >
            <div class="column left-section is-2 is-leftaligned">
                <button
                    class="button is-white"
                    disabled={history.length === 0 ? true : null}
                    on:click={goBack}
                    data-cy="fs-go-back"
                >
                    <span class="icon">
                        <span class="arrow left" on:click />
                    </span>
                </button>
            </div>
            <div class="column is-8 has-ellipsis">
                {#if history.length === 0}
                    <span>{i18n.t('App.Folder_Actions.Root_Folder_Name')}</span>
                {:else}
                    <abbr
                        class="ellipsed-asset-name"
                        title={folder.itemInfo.name}
                    >
                        {folder.itemInfo.name}
                    </abbr>
                {/if}
            </div>
            <div class="column is-2 is-rightaligned" data-cy="fs-new-folder">
                <NewFolderComponent parentFolderId={folder.id}>
                    <button
                        class="button is-white is-small"
                        slot="new-folder-trigger"
                    >
                        <span class="icon">
                            <AddIcon />
                        </span>
                    </button>
                </NewFolderComponent>
            </div>
        </div>
        <div class="content" slot="content">
            {#await getContent}
                <ListLoader />
            {:then folders}
                {#if folders.length > 0}
                    {#each folders as folderItem, i (folderItem.id)}
                        <div
                            class="folder-list-item-row columns is-mobile
                            is-vcentered is-marginless has-text-black-bis
                            can-hover"
                            class:has-top-border={i !== 0}
                            class:is-selected={selectedFolder.id ===
                                folderItem.id}
                        >
                            <div
                                class="column has-text-weight-semibold
                                    is-vertical-center is-10 has-ellipsis"
                                data-cy="fs-folder-list-item"
                                id={`${folderItem.idDecoded}-fs-item`}
                                on:click={selectFolder(folderItem)}
                            >
                                <span class="icon is-unselectable">
                                    <FolderIcon />
                                </span>
                                <abbr
                                    class="ellipsed-asset-name"
                                    title={folderItem.itemInfo.name}
                                >
                                    {folderItem.itemInfo.name}
                                </abbr>
                            </div>
                            <div
                                class="column is-2 is-rightaligned is-vertical-center"
                            >
                                <button
                                    class="button is-white is-small"
                                    on:click={gotoFolderHandler(folderItem)}
                                    data-cy="fs-goto-folder"
                                >
                                    <span class="icon">
                                        <span class="arrow right" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    {/each}
                {:else}
                    <EmptyFolder
                        message={i18n.t('App.Folder_Actions.No_Folders_Msg')}
                    />
                {/if}
            {:catch error}
                <SomethingWentWrong {error} source={`${dataCy}-content`} />
            {/await}
        </div>
        <div slot="footer-right-section" class="level-item">
            <button
                class="button is-outlined"
                data-cy="fs-close"
                on:click={modalInstance.close}
            >
                {i18n.t('App.Primary_Buttons.Close_Btn')}
            </button>
            <button
                class="ga-{dataCyFsExecute} button is-primary"
                on:click={execute}
                data-cy={dataCyFsExecute}
                disabled={!folder}
            >
                <slot name="action-name" />
            </button>
        </div>
    </ModalComponent>
{/if}

<style>
    .folder-list-item-row {
        height: 49px;
    }

    .content {
        min-height: 200px;
    }

    .percent {
        color: white !important;
        font-size: 8px;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
</style>

<script>
    import { i18n } from '../i18n';
    import NewFolderComponent from './new.folder.svelte';
    import UploadDropdown from './upload/upload.dropdown.svelte';
    import DesktopConnectorDownload from './desktop.connector.download.svelte';
    import UploadIcon from '../icons/upload.svelte';
    import AddIcon from '../icons/add.svelte';
    import config from 'app.config';
    import {
        currentFolder,
        isDisplayingSharedFolders,
    } from '../providers/state/active.record';
    import MyDataIcon from '../icons/drive.svelte';
    import PeopleIcon from '../icons/people.svelte';
    import { goToShared, goToHome } from '../providers/navigation';
    import {
        gBimUser,
        gPermitted,
        gFusionUser,
        gDriveInfo,
    } from '../providers/state/user';
    import { isTabletOrDesktop } from '../providers/device';

    const adskBlue = '#0696D7';
    const dataCyMyData = 'my-data';
    const dataCyNavSection = 'data-nav-section';
    const dataCySharedData = 'shared-with-me';
    const dataCyUpload = 'upload';
    const dataCyNewFolderButton = 'new-folder-button';
    const dataCyOtherLinks = 'other-links';
    const dataCyBim360Link = 'bim360-link';
    const dataCyFusion360Link = 'fusion360-link';
    const bim360Link = config.links.bim360;
    const fusion360Link = config.links.fusion360;

    let folder,
        canEdit = false,
        disableUploadAndNewFolder = true,
        isDisplayingSharedData = false,
        isPrivateShared = false;

    $: if (
        $gDriveInfo &&
        $currentFolder &&
        (!folder || $currentFolder.id !== folder.id)
    ) {
        folder = $currentFolder;

        canEdit = folder.canEdit;
        isPrivateShared = folder.isPrivateShared;
    }

    $: {
        isDisplayingSharedData =
            $isDisplayingSharedFolders || isPrivateShared ? true : false;
    }

    $: {
        disableUploadAndNewFolder =
            $isDisplayingSharedFolders || !$gPermitted || !canEdit
                ? true
                : false;
    }
</script>

<div class="section" id={dataCyNavSection} data-cy={dataCyNavSection}>
    <div class="section-body content-box">
        <button
            on:click={goToHome}
            class="ga-{dataCyMyData} button is-white is-fullwidth is-leftaligned
                has-text-black-ter is-shadowless"
            data-cy={dataCyMyData}
        >
            <span class="icon">
                <MyDataIcon fill={!isDisplayingSharedData ? adskBlue : ''} />
            </span>
            <span
                class="button-text"
                class:has-text-primary={!isDisplayingSharedData}
            >
                {i18n.t('App.My_Data')}
            </span>
        </button>
        <div class="is-gap" />
        <button
            on:click={goToShared}
            class="ga-{dataCySharedData} button is-white is-fullwidth is-leftaligned
                has-text-black-ter is-shadowless"
            data-cy={dataCySharedData}
        >
            <span class="icon">
                <PeopleIcon fill={isDisplayingSharedData ? adskBlue : ''} />
            </span>
            <span
                class="button-text"
                class:has-text-primary={isDisplayingSharedData}
            >
                {i18n.t('App.Shared_with_Me')}
            </span>
        </button>
        <div class="is-gap is-large is-divider" />
        <div class="is-gap" />
        {#if folder}
            <UploadDropdown asset={folder}>
                <button
                    class="ga-{dataCyUpload} button is-primary is-fullwidth is-leftaligned"
                    data-cy={dataCyUpload}
                    slot="trigger"
                    disabled={disableUploadAndNewFolder}
                >
                    <span
                        class="icon"
                        class:disabled-click={disableUploadAndNewFolder}
                    >
                        <UploadIcon />
                    </span>
                    <span class:disabled-click={disableUploadAndNewFolder}>
                        {i18n.t('App.Primary_Buttons.Upload_Btn')}
                    </span>
                </button>
            </UploadDropdown>
            <div class="is-gap is-medium" />
            <NewFolderComponent parentFolderId={folder.id}>
                <button
                    class="ga-{dataCyNewFolderButton} button is-fullwidth is-leftaligned
                            is-primary is-outlined"
                    data-cy={dataCyNewFolderButton}
                    slot="trigger"
                    disabled={disableUploadAndNewFolder}
                >
                    <span
                        class="icon"
                        class:disabled-click={disableUploadAndNewFolder}
                    >
                        <AddIcon
                            fill={disableUploadAndNewFolder
                                ? '#dcdcdc'
                                : adskBlue}
                        />
                    </span>
                    <span class:disabled-click={disableUploadAndNewFolder}>
                        {i18n.t('App.Primary_Buttons.New_Folder_Btn')}
                    </span>
                </button>
            </NewFolderComponent>
        {/if}
        {#if navigator.platform === 'Win32'}
            <div class="is-gap is-medium" />
            <div class="is-gap is-large is-divider" />
            <div class="is-fullwidth subtitle is-7 has-text-weight-medium">
                {i18n.t('App.User_Content.Desktop_Connector_Sync_Files_Msg')}
            </div>
            <div class="is-fullwidth has-centered-content">
                <img
                    class="cloud-sync-img"
                    src="DRIVE_ASSETS/images/cloud-sync.png"
                    alt="cloud-sync"
                />
            </div>
            <div class="is-gap is-medium" />
            <DesktopConnectorDownload
                hasIcon={false}
                isFullWidth={true}
                isCenterAligned={true}
            >
                <span slot="title">
                    {i18n.t('App.Primary_Buttons.Get_Desktop_Connector_Btn')}
                </span>
            </DesktopConnectorDownload>
        {/if}
        {#if $isTabletOrDesktop}
            {#if $gBimUser || $gFusionUser}
                <div class="is-gap is-medium" />
                <div class="is-gap is-large is-divider" />
                <div
                    class="is-fullwidth subtitle is-7 has-text-weight-medium"
                    data-cy={dataCyOtherLinks}
                >
                    {i18n.t('App.Misc.External_Product_Link_Msg')}
                </div>

                {#if $gBimUser}
                    <a
                        class="ga-{dataCyBim360Link} button is-primary is-outlined
                                is-fullwidth is-leftaligned has-text-weight-medium"
                        href={bim360Link}
                        data-cy={dataCyBim360Link}
                        target="_blank"
                    >
                        <span class="icon">
                            <span class="external-icon">
                                <img
                                    src="DRIVE_ASSETS/images/autodesk-docs.png"
                                    alt="bim-logo"
                                />
                            </span>
                        </span>
                        <span>
                            {i18n.t('App.Primary_Buttons.Adsk_Docs_Btn')}
                        </span>
                    </a>
                {/if}
                {#if $gBimUser && $gFusionUser}
                    <div class="is-gap is-medium" />
                {/if}
                {#if $gFusionUser}
                    <a
                        class="ga-{dataCyFusion360Link} button is-primary is-outlined
                                is-fullwidth is-leftaligned has-text-weight-medium"
                        href={fusion360Link}
                        data-cy={dataCyFusion360Link}
                        target="_blank"
                    >
                        <span class="icon">
                            <span class="external-icon">
                                <img
                                    src="DRIVE_ASSETS/images/fusion-logo-256x256.png"
                                    alt="fusion-logo"
                                />
                            </span>
                        </span>
                        <span>
                            {i18n.t('App.Primary_Buttons.Fusion_Btn')}
                        </span>
                    </a>
                {/if}
            {/if}
        {/if}
    </div>
</div>

<style>
    .button-text {
        margin-left: 9px;
    }
    .cloud-sync-img {
        height: 100px;
        right: 0;
        top: 30px;
    }
    .external-icon {
        width: 20px;
        height: 20px;
    }
</style>

<script>
    import { i18n } from '../i18n';
    import { ListLoader } from 'svelte-content-loader';
    import EmptyFolder from '../components/folder.content/empty.folder.svelte';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import FolderIcon from '../icons/folder.svelte';
    import FileIcon from '../icons/file.svelte';
    import { gDriveInfo } from '../providers/state/user';
    import { FolderFactory } from '../rest.sdks';

    let lastError;
    let getContent = [];
    let history = [];
    let selectedFile = {};
    let folder;
    let dataCyFilePicker = 'file-picker';

    $: {
        if ($gDriveInfo) {
            if (!folder) {
                const { rootFolderId } = $gDriveInfo;
                folder = FolderFactory(rootFolderId);
            }

            assignGetContent();
            folder.observe({
                content: () => {
                    assignGetContent();
                },
            });
        }
    }

    const assignGetContent = () => {
        getContent = folder.content.sortedByName(true);
    };

    const goBack = () => {
        folder = history.pop();
        // https://svelte.dev/tutorial/updating-arrays-and-objects
        history = [...history];
        selectedFile = {};
    };

    const gotoFolderHandler = (folderItem) => {
        return () => {
            if (folder) {
                // https://svelte.dev/tutorial/updating-arrays-and-objects
                history = [...history, folder];
            }
            folder = folderItem;
            selectedFile = {};
        };
    };

    const closeWindow = () => {
        // Scripts may close only the windows that were opened by them.
        if (window.opener) {
            window.close();
        } else {
            window.location.href = 'https://autodesk.com';
        }
    };

    const selectFile = (fileItem) => {
        return () => {
            selectedFile = fileItem;
        };
    };

    const getDownloadLink = (fileItem) => {
        return async () => {
            try {
                const parentWindow = window.opener;
                if (parentWindow) {
                    const downloadLink = await fileItem.getDownloadLink();
                    const itemInfo = fileItem.itemInfo;
                    const responseObj = {
                        downloadLink,
                        name: itemInfo.name,
                        title: itemInfo.name,
                        fileType: fileItem.fileType,
                        created: itemInfo.created,
                        mimeType: itemInfo.mimeType,
                        versionNumber: itemInfo.versionNumber,
                    };

                    // TODO: We should be stricter here to post messages only to a specific domain.
                    // Later on, when we register pickers, clients can specify the domains that
                    // we allow. We can add that validation check here.
                    parentWindow.postMessage(responseObj, '*');

                    window.close();
                } else {
                    alert(fileItem.itemInfo.name);
                }
            } catch (err) {
                lastError = err;
            }
        };
    };
</script>

{#if lastError}
    <SomethingWentWrong error={lastError} source={dataCyFilePicker} />
{:else if folder}
    <section class="section">
        <div class="section-head">
            <div
                class="container is-fluid columns is-marginless
                    has-all-space is-vertical-center"
            >
                <div class="column">
                    <button
                        class="button is-white"
                        disabled={history.length === 0 ? true : null}
                        on:click={goBack}
                        data-cy="file-picker-go-back"
                    >
                        <span class="icon">
                            <span class="arrow left" on:click />
                        </span>
                    </button>
                </div>
                <div
                    class="column is-horizontal-center
                        has-text-weight-semibold"
                >
                    <span>
                        {#if history.length === 0}
                            {i18n.t('App.My_Data')}
                        {:else}
                            {folder.itemInfo.name}
                        {/if}
                    </span>
                </div>
                <div class="column">
                    <span />
                </div>
            </div>
        </div>
        <div class="section-body">
            <div
                class="container is-fluid columns has-all-space
                    is-marginless"
            >
                <div class="column is-paddingless content-section">
                    <div class="content">
                        {#await getContent}
                            <ListLoader />
                        {:then content}
                            {#if content.count > 0}
                                {#each content.folders as folderItem, i (folderItem.id)}
                                    <div
                                        class="folder-list-item-row columns
                                            is-mobile is-vcentered is-marginless
                                            has-text-black-bis can-hover"
                                        class:has-top-border={i !== 0}
                                    >
                                        <div
                                            class="column
                                                    has-text-weight-semibold
                                                    is-vertical-center"
                                            data-cy="file-picker-folder-list-item"
                                            id={`${folderItem.idDecoded}-fs-item`}
                                            on:click={gotoFolderHandler(
                                                folderItem
                                            )}
                                        >
                                            <span
                                                class="icon
                                                        is-unselectable"
                                            >
                                                <FolderIcon />
                                            </span>
                                            <span
                                                >{folderItem.itemInfo
                                                    .name}</span
                                            >
                                        </div>
                                        <div
                                            class="column is-narrow
                                                    is-vertical-center"
                                        >
                                            <button
                                                class="button is-white
                                                        is-small"
                                                on:click={gotoFolderHandler(
                                                    folderItem
                                                )}
                                                data-cy="file-picker-goto-folder"
                                            >
                                                <span class="icon">
                                                    <span
                                                        class="arrow
                                                                right"
                                                    />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                {/each}
                                {#each content.files as fileItem (fileItem.id)}
                                    <div
                                        class="selectable-item
                                            file-list-item-row columns is-mobile
                                            is-vcentered is-marginless
                                            has-bottom-border has-text-black-bis
                                            can-hover has-top-border"
                                        class:is-selected={selectedFile.id ===
                                            fileItem.id}
                                        on:click={selectFile(fileItem)}
                                    >
                                        <div
                                            class="column
                                                    is-one-third-desktop
                                                    has-text-weight-semibold
                                                    is-vertical-center
                                                    file-name-column"
                                            data-cy="file-picker-file-list-item"
                                        >
                                            <span class="icon">
                                                <FileIcon
                                                    type={fileItem.fileType}
                                                />
                                            </span>
                                            <span
                                                class="main-text
                                                        has-ellipsis"
                                            >
                                                {fileItem.itemInfo.name}
                                            </span>
                                        </div>
                                    </div>
                                {/each}
                            {:else}
                                <EmptyFolder
                                    message={i18n.t(
                                        'App.Folder_Actions.No_Folders_Msg'
                                    )}
                                />
                            {/if}
                        {:catch error}
                            <SomethingWentWrong
                                {error}
                                source={'file-picker-content'}
                            />
                        {/await}
                    </div>
                </div>
            </div>
        </div>
        <div class="is-gap" />
        <div class="section-footer">
            <div class="container is-fluid is-rightaligned">
                <button
                    class="button is-outlined"
                    data-cy="file-picker-close-button"
                    on:click={closeWindow}
                >
                    {i18n.t('App.Primary_Buttons.Close_Btn')}
                </button>
                <div class="is-gap is-horizontal" />
                <button
                    class="button is-primary"
                    on:click={getDownloadLink(selectedFile)}
                    disabled={!selectedFile.id}
                    data-cy="file-picker-pick-button"
                >
                    {i18n.t('App.File_Picker.Pick_File')}
                </button>
            </div>
        </div>
        <div class="is-gap" />
    </section>
{/if}

<style>
    .folder-list-item-row {
        height: 49px;
    }
</style>

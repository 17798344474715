<script>
    export let assets = [];
    export let onClose = () => {};
    export let open = true;
    export let viewable = null;

    import { notificationsStore } from '../../components/elements/notifications.store.js';
    import DriveError from '../../rest.sdks/drive.error.js';

    const close = () => {
        onClose();
    };

    // TODO: Present the progress to the user somehow...
    let progress = 0; // eslint-disable-line no-unused-vars
    const progressCB = (percent) => {
        progress = percent;
    };

    const showNotification = (type, msgKey, name, timeout = 3000) => {
        return notificationsStore.add({
            type,
            body: `App.Download_Actions.${msgKey}`,
            bodyParams: { NAME: name },
            timeout,
            onlyOne: false, // Allow the same notification to be displayed multiple times
        });
    };

    // const warnDownloadInProgress = (e) => {
    //     e.preventDefault();
    //     return (e.returnValue =
    //         'A download is in progress, are you sure to leave Autodesk Drive?');
    // };

    const downloadAssets = async () => {
        if (assets) {
            close();
            let inProgressToastId,
                isFile = true,
                name;
            try {
                if (assets.length === 1) {
                    if (assets[0].type === 'share') {
                        name = viewable.name;
                        inProgressToastId = showNotification(
                            'info',
                            'Single_File_Prepare',
                            name,
                            0
                        );
                        await assets[0].downloadPublicShare(viewable);
                        notificationsStore.close(inProgressToastId);
                        showNotification(
                            'success',
                            'Single_File_Success',
                            name
                        );
                    } else {
                        const metadata = assets[0].itemInfo;
                        if (assets[0].isFile) {
                            name = metadata.name;
                            inProgressToastId = showNotification(
                                'info',
                                'Single_File_Prepare',
                                name,
                                0
                            );
                            await assets[0].downloadFile();
                            notificationsStore.close(inProgressToastId);
                            showNotification(
                                'success',
                                'Single_File_Success',
                                name
                            );
                        } else {
                            isFile = false;
                            name = metadata.name;
                            inProgressToastId = showNotification(
                                'info',
                                'Folder_Prepare',
                                name,
                                0
                            );
                            await assets[0].downloadFolder(progressCB);
                            notificationsStore.close(inProgressToastId);
                            showNotification('success', 'Folder_Success', name);
                        }
                    }
                } else {
                    // DRIVE-467 and DRIVE-935 DriveMS doesn't support this
                    // await folder.bulkDownload(assets, progressCB);
                }
            } catch (error) {
                notificationsStore.close(inProgressToastId);
                showNotification(
                    'danger',
                    isFile ? 'Single_File_Failure' : 'Folder_Failure',
                    name,
                    0
                );
                // Track the underlying error
                DriveError.logError('download-item', error);
            }
            open = false; // Reset open status so that user can re-download
        }
    };
</script>

{#if open}
    <!-- prettier-ignore -->
    {#await downloadAssets()}
    {/await}
{/if}

<div class="trigger" on:click={() => (open = true)}>
    <slot name="download-folder-trigger" />
</div>

<script>
    import { i18n } from '../../i18n';
    export let message = i18n.t('App.Folder_Content_List.Upload_Files_Msg');
</script>

<div class="section has-centered-content mt-4" data-cy="empty-folder">
    <span class="icon">
        <img src="DRIVE_ASSETS/images/empty-box-212x172.png" alt="empty-box" />
    </span>
    <div class="is-gap" />
    <div class="subtitle is-6">{message}</div>
</div>

<style>
    .icon {
        width: 212px;
        height: 172px;
    }
</style>

<script>
    /* A Basic Svelte wrapper for https://materializecss.com/sidenav.html */
    export let options = {};
    export let id = `sidenav-${randomHex()}`;
    export let instance = null;
    export let open = false;
    export let dataCy = 'sidenav-unknown';

    import { randomHex } from '../../providers/utils';
    import { onDestroy, onMount } from 'svelte';
    import { fly } from 'svelte/transition';
    import CloseIcon from '../../icons/close.svelte';

    const edge = options.edge || 'right';
    const positionRight = edge === 'right';

    let openSidemenu = !!open;

    instance = {
        open: () => {
            openSidemenu = true;
        },
        close: () => {
            openSidemenu = false;
        },
    };

    const animateOpts = {
        x: positionRight ? 1000 : -1000,
        duration: 100,
    };

    onMount(() => {
        if (openSidemenu) {
            instance.open();
        }
    });

    onDestroy(() => {
        instance = null;
    });
</script>

{#if openSidemenu}
    <section
        data-cy={dataCy}
        transition:fly|global={animateOpts}
        class="sidevnav-container section columns is-horizontal is-marginless"
        {id}
    >
        {#if positionRight}
            <div class="column" on:click={instance.close} />
        {/if}
        <div class="content column is-narrow section is-marginless box">
            <div class="section-head">
                <div class="container is-fluid columns is-vcentered is-mobile">
                    <div
                        class="column has-ellipsis is-vertical-center
                        is-paddingless"
                    >
                        <slot name="title" />
                    </div>
                    <div
                        data-cy="{dataCy}-close"
                        class="column is-narrow sidenav-close has-pointer-cursor
                        is-paddingless is-rightaligned"
                        on:click={instance.close}
                    >
                        <slot name="close">
                            <span class="icon">
                                <CloseIcon />
                            </span>
                        </slot>
                    </div>
                </div>
            </div>
            <div class="section-body">
                <slot name="content" />
            </div>
            <div class="section-foot">
                <slot name="footer" />
            </div>
        </div>
        {#if !positionRight}
            <div class="column" on:click={instance.close} />
        {/if}
    </section>
{/if}

<div class="sidenav-trigger" on:click={instance.open}>
    <slot name="trigger" />
</div>

<style>
    .sidevnav-container {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background-color: transparent;
    }
    .container {
        min-height: 60px;
        margin: 2px 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .content {
        background-color: #fff;
        min-width: 300px;
        width: 300px;
        max-width: 300px;
    }
    .sidenav-close {
        width: 50px;
    }
</style>
